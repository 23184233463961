<template>
  <div id="page-user-view">
    <div v-if="showActivities">
      <vx-card>
        <h2>{{ child.fullName }}'s Learning Stories</h2>
        <div class="flex flex-wrap ph-img-list-wrap ph-5-col">
          <div
            v-for="activity in activities"
            :key="activity._id"
            class="ph-img-list"
          >
            <a @click="activitySelect(activity._id)">
              <img
                :src="
                  activity.photos.length
                    ? activity.photos[0].photo
                    : $defaultImage
                "
                class="responsive h-64 object-cover"
              />
            </a>
            <span class="mt-5 block" @click="activitySelect(activity._id)">{{
              activity.name
            }}</span>
          </div>
        </div>
        <div
          class="pagination-div flex justify-center float-none mt-5"
          v-if="totalPages"
        >
          <paginate
            :page-count="totalPages"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
          ></paginate>
        </div>
      </vx-card>
    </div>
    <div v-if="showActivityDetail">
      <h2 class="mx-10" @click="goBack" style="cursor:pointer">
        Learning stories
      </h2>
      <vx-card>
        <div class="vx-breadcrumb mb-5 md:block hidden">
          <ul class="flex flex-wrap items-center">
            <li class="inline-flex items-end">
              <a class="" @click="goBack"> Learning stories</a>
              <span class="breadcrumb-separator mx-2">
                <span class="feather-icon select-none relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right w-4 h-4"
                  >
                    <polyline points="13 17 18 12 13 7"></polyline>
                    <polyline points="6 17 11 12 6 7"></polyline>
                  </svg>
                </span>
              </span>
            </li>
            <li class="inline-flex items-center">
              <a class="">
                {{
                  moment(selectedActivity.updatedAt) +
                    " - " +
                    selectedActivity.name
                }}</a
              >
            </li>
          </ul>
        </div>
        <div class="flex items-center mb-5">
          <h2 class="mb-0">{{ selectedActivity.name }}</h2>
          <div class="ml-auto ">
            <a
              @click="openMailPopup"
              class="block vs-component vs-button vs-button-primary vs-button-filled"
            >
              Respond
            </a>
          </div>
        </div>
        <p class="full-width-detail">{{ selectedActivity.description }}</p>
        <div class="w-full mt-10">
          <ul class="flex">
            <li class="pr-5 md:pr-10 text-center">
              <strong>Core Skill</strong>
              <img
                :src="
                  selectedActivity.coreSkills.icon
                    ? selectedActivity.coreSkills.icon
                    : $defaultImage
                "
                :alt="selectedActivity.coreSkills.name"
                class="h-16 object-contain block mx-auto mt-5"
              />
              {{ selectedActivity.coreSkills.name }}
            </li>
            <li class="px-5 md:px-10 text-center">
              <strong>Ways of Learning</strong>
              <img
                :src="
                  selectedActivity.waysOfLearnings.icon
                    ? selectedActivity.waysOfLearnings.icon
                    : $defaultImage
                "
                :alt="selectedActivity.waysOfLearnings.name"
                class="h-16 object-contain block mx-auto mt-5"
              />
              {{ selectedActivity.waysOfLearnings.name }}
            </li>
            <li class="pl-5 md:pl-10 text-center">
              <strong>STREAM</strong>
              <img
                :src="
                  selectedActivity.streams.icon
                    ? selectedActivity.streams.icon
                    : $defaultImage
                "
                :alt="selectedActivity.streams.name"
                class="h-16 object-contain block mx-auto mt-5"
              />
              {{ selectedActivity.streams.name }}
            </li>
          </ul>
        </div>
        <div class="mt-10">
          <h4 class="font-bold">Play with Purpose. (Your Intentionality)</h4>
          {{ selectedActivity.purposeStatement }}
        </div>
        <div class="mt-10">
          <h4 class="font-bold">
            How has {{ firstName(child.fullName) }} changed? (Assessment of Learning)
          </h4>
          {{ selectedActivity.children.howHasChanged }}
        </div>
        <div class="flex flex-wrap ph-img-list-wrap ph-5-col">
          <div
            v-for="(photo, index) in selectedActivity.photos"
            :key="photo._id"
            class="ph-img-list"
          >
            <a href="javascript::void(0)" @click.prevent="openGallery(index)">
              <img :src="photo.photo" class="responsive h-64 object-cover" />
            </a>
          </div>
        </div>
        <LightBox
          ref="lightbox"
          :media="media"
          :show-light-box="false"
          :show-caption="false"
          :show-thumbs="false"
          @onImageChanged="onImageChanged"
        />
        <div class="mt-10">
          <h4 class="font-bold">{{ firstName(child.fullName) }}'s Voice</h4>
          {{ selectedActivity.children.childVoice }}
        </div>
        <div class="mt-10">
          <h4 class="font-bold">Core skill reflection</h4>
          {{ selectedActivity.children.coreSkillReflection }}
        </div>
      </vx-card>
      <vx-card style="margin-top: 30px" v-if="selectedActivity.followUp">
        <h4 class="font-bold">
          FOLLOW UP THE LEARNING1: {{ selectedActivity.followUp.name }}
        </h4>
        <p>{{ selectedActivity.followUp.description }}</p>

        <div class="w-full mt-10">
          <ul class="flex">
            <li class="pr-5 md:pr-10 text-center">
              <strong>Core Skill</strong>
              <img
                :src="
                  selectedActivity.followUp.coreSkills.icon
                    ? selectedActivity.followUp.coreSkills.icon
                    : $defaultImage
                "
                :alt="selectedActivity.followUp.coreSkills.name"
                class="h-16 object-contain block mx-auto mt-5"
              />
              {{ selectedActivity.followUp.coreSkills.name }}
            </li>
            <li class="px-5 md:px-10 text-center">
              <strong>Ways of Learning</strong>
              <img
                :src="
                  selectedActivity.followUp.waysOfLearnings.icon
                    ? selectedActivity.followUp.waysOfLearnings.icon
                    : $defaultImage
                "
                :alt="selectedActivity.followUp.waysOfLearnings.name"
                class="h-16 object-contain block mx-auto mt-5"
              />
              {{ selectedActivity.followUp.waysOfLearnings.name }}
            </li>
            <li class="pl-5 md:pl-10 text-center">
              <strong>STREAM</strong>
              <img
                :src="
                  selectedActivity.followUp.streams.icon
                    ? selectedActivity.followUp.streams.icon
                    : $defaultImage
                "
                :alt="selectedActivity.followUp.streams.name"
                class="h-16 object-contain block mx-auto mt-5"
              />
              {{ selectedActivity.followUp.streams.name }}
            </li>
          </ul>
        </div>
        <div class="mt-10">
          <h4 class="font-bold">Teacher's Comment</h4>
          {{ selectedActivity.followUp.followUpCompletedText }}
        </div>
      </vx-card>
      <vx-card class="mt-10">
        <h2>Approved Learning Framework: Where can this play with purpose take {{ child.fullName }}</h2>
        <vs-row>
          <vs-col
            v-for="(category, key) in categories"
            :key="key"
            vs-lg="6"
            vs-sm="12"
            vs-xs="12"
            class="eylf-col px-0 md:pr-10 border-b-0 mb-5 md:mb-10"
          >
            <h4 class="font-bold mb-5">
              Learning Framework {{ category.title }}
            </h4>
            <ul>
              <li
                v-for="(outcome, index) in category.eylfChecklists"
                :key="index + 0.2"
                class="flex items-center mb-3"
              >
                <img
                  :src="
                    isEylfExist(outcome._id)
                      ? $defaultIconTick
                      : $defaultIconMinus
                  "
                  class="mr-5"
                />
                <strong class="mr-3">{{ category.title | singularize }} {{ index + 1 }}:</strong>
                {{ outcome.title }}
                <vx-tooltip
                  :text="outcome.description"
                  color="#000000"
                  position="right"
                  class="inline-block"
                >
                  <a href="#" class="ml-3">
                    <feather-icon icon="InfoIcon" style="width: 15px;" />
                  </a>
                </vx-tooltip>
              </li>
            </ul>
          </vs-col>
        </vs-row>
      </vx-card>
      <vs-popup
        class="holamundo add-children-popup"
        title
        :active.sync="mailPopup"
      >
        <h2 class="h1 mb-6">Respond</h2>
        <form class="w-full md:w-1/1">
          <vs-row>
            <vs-col class="w-full input-wrap">
              <vs-input
                class="w-full mt-5"
                v-model="subject"
                name="subject"
                v-validate="'required'"
                label-placeholder="Subject"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('subject')"
                >{{ errors.first("subject") }}</span
              >
            </vs-col>
            <vs-col class="w-full input-wrap mt-5">
              <vs-textarea
                :class="content ? 'hasValue' : ''"
                :label="'Description'"
                v-model="content"
                name="content"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('content')"
                >{{ errors.first("content") }}</span
              >
            </vs-col>
          </vs-row>
          <div class="flex">
            <vs-button
              @click="sendMail"
              color="primary"
              class="mt-5 ml-auto"
              type="filled"
              >Respond
            </vs-button>
          </div>
        </form>
      </vs-popup>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import Vue from "vue";
import VueLodash from "vue-lodash";
import _ from "lodash";
import LightBox from "vue-image-lightbox";
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";

Vue.use(VueLodash, { name: "custom", lodash: _ });

export default {
  components: { LightBox },
  data() {
    return {
      itemPerPage: 15,
      page: 1,
      totalPages: 0,
      activities: [],
      showActivities: true,
      showActivityDetail: false,
      selectedActivity: "",
      categories: [],
      mailPopup: false,
      subject: "",
      content: "",
      currentImageIndex: 0,
      media: []
    };
  },
  methods: {
    ...mapActions("activity", [
      "fetchCompletedActivityByChildId",
      "fetchActivityByActivityIdAndChildId"
    ]),
    ...mapActions("eylfCategory", ["fetchEYLFCategoryWithFrameworkByCenterId"]),
    ...mapActions("parent", ["sendParentRespondMailFromLearningStory"]),
    firstName(childFullName) {
      let spaceIndex = childFullName.indexOf(" ");
      return spaceIndex === -1
        ? childFullName
        : childFullName.substr(0, spaceIndex);
    },
    openMailPopup() {
      this.init();

      this.errors.clear();
      this.$nextTick(() => {
        this.$validator.reset();
      });
      this.mailPopup = true;
    },
    init() {
      this.subject = "";
      this.content = "";
    },
    sendMail() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          let data = {
            subject: this.subject,
            content: this.content,
            learningStoryId: this.selectedActivity._id,
            learningStoryName: this.selectedActivity.name,
            name: this.parent.fullName,
            email: this.parent.email,
            centerEmail: this.child.learningCenter.email,
            centerName: this.child.learningCenter.name
          };
          this.$vs.loading();
          await this.sendParentRespondMailFromLearningStory(data)
            .then(result => {
              if (result.status === 200) {
                this.$vs.loading.close();

                this.mailPopup = false;
                this.$vs.notify({
                  title: "Success",
                  text: "Respond mail sent successfully..",
                  color: "success",
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-check-circle"
                });
              }
            })
            .catch(err => {
              this.$vs.loading.close();

              this.$vs.notify({
                title: "Error",
                text: "Respond mail could not be sent. Please try again later.",
                color: "danger",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-times"
              });
            });
        }
      });
    },
    moment(date) {
      return moment(date).format("DD/MM/YY");
    },
    isEylfExist(key) {
      return _.includes(this.selectedActivity.eylfFrameworks, key);
    },
    async getActivities(childId, page) {
      this.$vs.loading();
      const data = {
        childId: childId,
        page: page,
        limit: this.itemPerPage
      };
      await this.fetchCompletedActivityByChildId(data).then(response => {
        this.$vs.loading.close();
        if (response.data.data.docs.length) {
          this.activities = response.data.data.docs;
          this.totalPages = Math.ceil(
            response.data.data.pagination.total / this.itemPerPage
          );
        }
      });
      this.$vs.loading.close();
    },
    handleChangePage(page) {
      this.getActivities(this.childId, page);
    },
    async activitySelect(activityId) {
      const data = {
        activityId: activityId,
        childId: this.childId
      };
      this.showActivities = false;

      await this.fetchActivityByActivityIdAndChildId(data).then(response => {
        this.selectedActivity = response.data.data;
        this.showActivityDetail = true;
        if (this.selectedActivity.photos.length > 0) {
          this.media = this.selectedActivity.photos.map(photo => {
            return {
              id: photo._id,
              thumb: photo.photo,
              src: photo.photo
            };
          });
        }
      });
    },
    async getEylfChecklist(centerId) {
      await this.fetchEYLFCategoryWithFrameworkByCenterId(centerId).then(
        checklist => {
          this.categories = checklist.data.data.eylfCategories;
        }
      );
    },
    goBack() {
      this.showActivities = true;
      this.showActivityDetail = false;
    },
    onImageChanged(index) {
      this.currentImageIndex = index;
    },
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
      this.currentImageIndex = index;
    }
  },
  mounted() {
    this.getActivities(this.childId, this.page);
    this.getEylfChecklist(this.child.learningCenterId);
  },
  computed: {
    parent() {
      return this.$store.state.AppActiveUser;
    },
    child() {
      return JSON.parse(localStorage.getItem("child"));
    },
    children() {
      return localStorage.getItem("parentChildren");
    },
    childId() {
      return this.$route.params.id ? this.$route.params.id : this.child._id;
    }
  }
};
</script>
<style lang="scss">
.vs-tooltip {
  z-index: 100000;
}

.vs-tooltip {
  color: #222222;
  font-size: 15px;
  line-height: 20px;
  padding: 15px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
  min-width: 300px !important;
}
</style>
