<template>
  <div>
    <vx-card>
      <div class="datepicker-wrapper hasIcon" style="width: 15%;">
        <vs-icon icon="calendar_today" class="icon"></vs-icon>
        <datepicker
          icon="icon icon-user"
          icon-pack="feather"
          :format="dateFormat"
          v-model="selectedDate"
          class="w-full"
        ></datepicker>
      </div>
      <div
        id="data-list-thumb-view"
        class="data-list-container daily-diary-table"
      >
        <table class="table w-full">
          <thead>
            <tr>
              <th class="activity">Activity</th>
              <th class="description">Description</th>
              <th class="core-skill text-center">Core skill</th>
              <th class="ways-of-learning text-center">Ways of learning</th>
              <th class="stream text-center">STREAM</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="activities.length > 0"
              v-for="activity in activities"
              :key="activity._id"
            >
              <td valign="top" class="activity">{{ activity.name }}</td>
              <td colspan="4" class="px-0">
                <table class="inner-table">
                  <tbody>
                    <tr>
                      <td valign="top" class="description">
                        {{ activity.description }}
                      </td>

                      <td valign="top" class="core-skill text-center">
                        <img
                          v-if="activity.coreSkill.icon !== ''"
                          :src="activity.coreSkill.icon"
                          class="inline h-16 object-contain"
                        />
                        <br />
                        {{ activity.coreSkill ? activity.coreSkill.name : "" }}
                      </td>
                      <td valign="top" class="ways-of-learning text-center">
                        <img
                          v-if="activity.waysOfLearning.icon"
                          :src="activity.waysOfLearning.icon"
                          class="inline h-16 object-contain"
                        />
                        <br />
                        {{
                          activity.waysOfLearning
                            ? activity.waysOfLearning.name
                            : ""
                        }}
                      </td>
                      <td valign="top" class="stream text-center">
                        <img
                          v-if="activity.streams.icon"
                          :src="activity.streams.icon"
                          class="inline h-16 object-contain"
                        />

                        <br />
                        {{ activity.streams ? activity.streams.name : "" }}
                      </td>
                    </tr>
                    <tr v-if="activity.photos">
                      <td colspan="4">
                        <ul class="flex flex-wrap">
                          <li
                            v-for="photo in activity.photos"
                            :key="photo._id"
                            class="w-1/3 sm:w-1/4 xl:w-2/12"
                          >
                            <img
                              :src="photo.photo"
                              class="w-full h-32 object-contain object-left-top print-view"
                            />
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr v-if="activities.length <= 0">
              <td colspan="5" class="text-center">
                There are no activities for this day.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </vx-card>
    <vx-card>
      <h2>Today's Comments</h2>
      <ul>
        <li
          v-if="comments.length > 0"
          v-for="(comment, key) in comments"
          :key="key"
        >
          - {{ comment.comment }}
        </li>
        <li v-if="comments.length < 1">no daily comment found.</li>
      </ul>
    </vx-card>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import axios from "../../../../http/axios/index.js";


export default {
  components: { Datepicker },
  data() {
    return {
      dateFormat: "dsu MMMM yyyy",
      selectedDate: "",
      activities: [],
      comments: []
    };
  },
  watch: {
    selectedDate(response) {
      let todayDate = new Date();
      let selectedDate = new Date(response);

      let selectedDateForHeading =
        selectedDate.getMonth() +
        1 +
        "/" +
        selectedDate.getDate() +
        "/" +
        selectedDate.getFullYear();

      if (todayDate.setHours(0, 0, 0, 0) == selectedDate.setHours(0, 0, 0, 0)) {
        this.dateFormat = "dsu MMMM yyyy (Today)";
        this.dateTitle = "Today " + selectedDateForHeading;
      } else {
        this.dateFormat = "dsu MMMM yyyy";
        this.dateTitle = selectedDateForHeading;
      }
      this.getActivities(selectedDate);
      this.getDailyComment(selectedDate);
    }
  },
  methods: {
    ...mapActions("activity", ["fetchParentDailyDiary"]),
    ...mapActions("parent", ["fetchDailyComment"]),
    async getTodayDate() {
      this.selectedDate = await new Date().toISOString().slice(0, 10);
    },
    async getActivities(date) {
      this.$vs.loading();
      let param = "?date=" + date;
      let data = { childId: this.childId, date: param };

      this.fetchParentDailyDiary(data)
        .then(res => {
          this.$vs.loading.close();

          this.activities = res.data.data;
        })
        .catch(err => {
          this.$vs.loading.close();
          console.log(err);
        });
    },
    async getDailyComment(date) {
      const data = {
        roomId: this.child.roomId,
        date: date
      };
      await this.fetchDailyComment(data)
        .then(async res => {
          this.comments = await res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },

  async mounted() {
      await this.getTodayDate();
  },
  computed: {
    parent() {
      return this.$store.state.AppActiveUser;
    },
    child() {
      return JSON.parse(localStorage.getItem("child"));
    },
    childId() {
      return this.$route.params.id ? this.$route.params.id : this.child._id;
    }
  }
};
</script>
